import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"

export const ShareMenu = ({
  isOpen: initialOpenState = false,
  children,
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(initialOpenState)
  const menuListRef = useRef(null)
  const menuButtonRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      if (menuListRef.current) {
        menuListRef.current
        menuListRef.current.firstChild.focus()
      }
    }
  }, [isOpen])

  const handleClickOutside = e => {
    if (
      menuListRef.current.contains(e.target) ||
      menuButtonRef.current.contains(e.target)
    ) {
      return
    }

    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  function toggleIsOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <aside className={`share-menu ${className}`} {...props}>
      <button
        className="button button--beige button--share"
        onClick={toggleIsOpen}
        ref={menuButtonRef}
      >
        Share
      </button>

      <ul
        className="share-menu__list"
        style={{ display: isOpen ? "block" : "none" }}
        ref={menuListRef}
      >
        {children}
      </ul>
    </aside>
  )
}

ShareMenu.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export const ShareMenuItem = ({ children, ...props }) => (
  <li {...props}>{children}</li>
)
