import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import authorImg from "../../images/author.jpg"
import heroImg from "../../images/blogpost-hero.jpg"
import blogImg from "../../images/hero-warehouse.jpg"
import { ShareMenu, ShareMenuItem } from "../../components/ShareMenu"

const Tool = ({
  title = "Default blog title",
  author = "David Engle",
  date = "May 2, 2020",
}) => {
  return (
    <Layout>
      <SEO title={title} />

      <article className="container blogpost">
        <header className="blogpost__header">
          <nav className="resources__breadcrumb">
            <Link to="/resources" className="link--arrow-left">
              Resources
            </Link>
            <Link to="/tools">Tools</Link>
          </nav>

          <h1 className="blogpost__title">{title}</h1>

          <div className="blogpost__meta">
            <img
              src={authorImg}
              alt={author}
              className="blogpost__author-img"
            />
            <h4 className="blogpost__author">{author}</h4>
            <time className="blogpost__date">{date}</time>
            <ul className="blogpost__tags">
              <li>Operations</li>
              <li>Optimization</li>
            </ul>
          </div>

          <img src={heroImg} alt={title} className="blogpost__hero" />
        </header>

        <section className="blogpost__body grid">
          <div className="blogpost__body-content">
            <p>
              After integrating Upright Lister in February 2019 and using the
              platform for a few months, Goodwill San Antonio was able to
              achieve significant time savings for each listing they were
              putting online.
            </p>
            <p>
              While these features have been extremely useful to Goodwill San
              Antonio, Upright Link, the iOS-based photography tool has been
              even more impactful on the e-commerce workflow provided Goodwill
              San Antonio has volunteers and team members with disabilities who
              assist with photo taking and listing of items online.
            </p>
            <p>
              “The simplicity and user interface design of the Upright Lister
              and Upright Link software applications make it easy for any
              employee or volunteer to learn and grasp the software in a matter
              of minutes, versus hours.”
            </p>
            <p>
              And although the software is designed to be both easy to use and
              easy to onboard new employees onto, any questions that arise and
              requested features are taken seriously and immediately evaluated.
            </p>

            <h2>Header h2</h2>
            <h3>Header h3</h3>
            <h4>Header h4</h4>
            <h5>Header h5</h5>
            <h6>Header h6</h6>

            <img src={blogImg} alt="alt" />

            <p>
              After integrating Upright Lister in February 2019 and using the
              platform for a few months, Goodwill San Antonio was able to
              achieve significant time savings for each listing they were
              putting online.
            </p>

            <blockquote>
              <p>
                “The simplicity and user interface design of the Upright Lister
                and Upright Link software applications make it easy for any
                employee or volunteer to learn and grasp the software in a
                matter of minutes, versus hours.”
              </p>
            </blockquote>

            <ul>
              <li>After integrating Upright Lister in February 2019</li>
              <li>After integrating Upright Lister in February 2019</li>
              <li>After integrating Upright Lister in February 2019</li>
              <li>After integrating Upright Lister in February 2019</li>
            </ul>

            <p>
              While these features have been extremely useful to Goodwill San
              Antonio, Upright Link, the iOS-based photography tool has been
              even more impactful on the e-commerce workflow provided Goodwill
              San Antonio has volunteers and team members with disabilities who
              assist with photo taking and listing of items online.
            </p>

            <ol>
              <li>After integrating Upright Lister in February 2019</li>
              <li>After integrating Upright Lister in February 2019</li>
              <li>After integrating Upright Lister in February 2019</li>
              <li>After integrating Upright Lister in February 2019</li>
            </ol>
          </div>

          <ShareMenu className="blogpost__body-share">
            <ShareMenuItem>
              <a href="mailto:team@uprightlabs.com">Email</a>
            </ShareMenuItem>
            <ShareMenuItem>
              <a
                href="https://www.linkedin.com/company/uprightlabs"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </ShareMenuItem>
            <ShareMenuItem>
              <a href="">Print PDF</a>
            </ShareMenuItem>
          </ShareMenu>
        </section>
      </article>
    </Layout>
  )
}

export default Tool
